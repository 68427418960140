import React, { FC, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { theme } from '../styles/theme';

import { INDEX_LINK, CONTACT_US_LINK } from '../constants/links';

import { handleScroll } from '../helpers/scrollTop';

const Layout = styled.section`
  display: flex;
  padding: 80px 10% 200px;
  min-height: 100vh;
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 150px 50px 250px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
`;

const PageTitle = styled.h2`
  font-weight: 900;
  font-size: 22px;
  line-height: 1.45;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
  }
`;

const Heading = styled.h2`
  margin-top: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
  }
`;

const Paragraph = styled.p`
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 22px;
    text-align: justify;
  }
`;

const List = styled.ol`
  margin-left: 40px;
  margin-top: 20px;
`;

const ListItem = styled.li`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 22px;
    text-align: justify;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.orange};
`;

const DMCAPolicy: FC = () => {
  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='DMCA Policy' />
      <Header />
      <Layout>
        <Container>
          <PageTitle>DMCA Policy</PageTitle>
          <Paragraph>
            illume, company (&quot;Company&quot;) respects the intellectual property rights of third
            parties and responds to allegations that copyrighted material has been posted, uploaded
            or shared on or through the <Link href={INDEX_LINK}>https://illumenotes.com</Link>{' '}
            website or mobile device application(s) (the &quot;Service&quot;) without authorization
            from the copyright holder in accordance with the safe harbor set forth in the Digital
            Millennium Copyright Act (&quot;DMCA&quot;). Company will also, in appropriate
            circumstances and at its discretion, disable and/or terminate the accounts of users who
            may infringe or repeatedly infringe the copyrights of others in accordance with the
            DMCA.
          </Paragraph>
          <Heading>A. Notification of Alleged Copyright Infringement</Heading>
          <Paragraph>
            If you believe that your work has been copied and made available through the Service in
            a way that constitutes copyright infringement, you may send a written document to
            Company’s Designated Agent (as set forth below) that contains the following (a
            &quot;Notice&quot;):
          </Paragraph>
          <List>
            <ListItem>
              A description of the copyrighted work that you claim has been infringed.
            </ListItem>
            <ListItem>
              Identification of the URL or other specific location that contains the material that
              you claim infringes your copyright described in Item 1 above. You must provide us with
              reasonably sufficient information to locate the allegedly infringing material.
            </ListItem>
            <ListItem>
              An electronic or physical signature of the owner of the copyright or of the person
              authorized to act on behalf of the owner of the copyright.
            </ListItem>
            <ListItem>
              A statement by you that you have a good faith belief that the disputed use is not
              authorized by the copyright owner, its agent, or applicable law.
            </ListItem>
            <ListItem>
              A statement by you that the information contained in your Notice is accurate and that
              you attest under the penalty of perjury that you are the copyright owner or that you
              are authorized to act on the owner&apos;s behalf.
            </ListItem>
            <ListItem>Your name, mailing address, telephone number, and email address.</ListItem>
          </List>
          <Paragraph>
            Company’s Designated Agent for Notice of claims of copyright infringement can be reached
            as follows:
          </Paragraph>
          <Paragraph>
            illume, company
            <br />
            Attn: Sohayle Sizar
            <br />
            801 S. Grand Avenue
            <br />
            Los Angeles, CA 90017
            <br />
            E-Mail: <Link href={CONTACT_US_LINK}>email@illumenotes.com</Link>
          </Paragraph>
          <Paragraph>
            Please note that you may be liable for damages, including court costs and attorneys
            fees, if you misrepresent that content uploaded by a Service user is infringing your
            copyright.
          </Paragraph>
          <Paragraph>
            Upon receiving a proper Notice, Company will remove or disable access to the allegedly
            infringing material and notify the alleged infringer of your claim. We will also advise
            the alleged infringer of the DMCA Counter Notice Procedure described below in Section B
            by which the alleged infringer may respond to your claim and request that we restore
            this material.
          </Paragraph>
          <Heading>B. Counter Notice Procedure</Heading>
          <Paragraph>
            If you believe your own copyrighted material has been removed from the Service in error,
            you may submit a written Counter Notice to our Designated Agent (as identified above)
            that includes the following:
          </Paragraph>
          <List>
            <ListItem>
              Identification of the material that has been removed or disabled and the location at
              which the material appeared before it was removed or disabled.
            </ListItem>
            <ListItem>
              A statement that you consent to the jurisdiction of the Federal District Court in
              which your address is located, or if your address is outside the United States, the
              judicial district in which Company may be found.
            </ListItem>
            <ListItem>
              A statement that you will accept service of process from the party that filed the
              Notice or the party&apos;s agent.
            </ListItem>
            <ListItem>Your name, address and telephone number.</ListItem>
            <ListItem>
              A statement under penalty of perjury that you have a good faith belief that the
              material in question was removed or disabled as a result of mistake or
              misidentification of the material to be removed or disabled.
            </ListItem>
            <ListItem>Your physical or electronic signature.</ListItem>
          </List>
          <Paragraph>
            If you send our Designated Agent a valid, written Counter Notice meeting the
            requirements described above, we will restore your removed or disabled material within
            10 to 14 business days from the date we receive your Counter Notification, unless our
            Designated Agent first receives notice from the party filing the original Notice
            informing us that such party has filed a court action to restrain you from engaging in
            infringing activity related to the material in question.
          </Paragraph>
          <Paragraph>
            Please note that if you misrepresent that the disabled or removed content was removed by
            mistake or misidentification, you may be liable for damages, including costs and
            attorney&apos;s fees.
          </Paragraph>
          <Heading>C. Repeat Infringer Policy</Heading>
          <Paragraph>
            Company may withdraw all rights and privileges relating to the Service from any user who
            is deemed to be a repeat infringer. This determination will be based on the number of
            &quot;strikes&quot; against the user. A &quot;strike&quot; is counted against a user
            each time there is either: (i) an adjudication by a court, arbitrator or other tribunal
            of competent jurisdiction that the user has engaged in copyright infringement of any
            kind in relation to the Service; or (ii) Company has actual knowledge, regardless of any
            such adjudication, that the user has engaged in any such copyright infringement.
          </Paragraph>
          <Paragraph>
            Each adjudication or instance of knowledge counts as a separate strike. If an
            adjudication or instance of knowledge pertains to multiple instances of copyright
            infringement, it can count as multiple strikes. Company has adopted a &quot;three
            strikes and you’re out&quot; policy under which a user who accumulates three strikes is
            considered a repeat infringer and may be subject to account termination.
          </Paragraph>
        </Container>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default DMCAPolicy;
